/**
 * Мапа всех роутов приложения
 */
const ROUTES = {
    UNAUTHORIZED: {
        PATH: '/:unauthorizedStep(|login|)',
        LOGIN: {
            PATH: '/login',
        },
        REGISTRATION: {
            PATH: '/registration',
        },
        PASSWORD_RESTORE: {
            PATH: '/password/restore',
        },
        PASSWORD_RESTORE_CONFIRM: {
            PATH: '/password/restore/confirm',
        },
        PASSWORD_RESET: {
            PATH: '/password/reset/:token',
        },
    },
    APP: {
        PATH: '/',
    },
    PROMPTS: {
        PATH: '/prompt',
        PROMPT: {
            PATH: '/prompt/:promptId',
        },
    },
    PROFILE: {
        PATH: '/profile',
    },
    ERROR: {
        PATH: '*',
    },
    REGISTRATION_CONFIRM: {
        PATH: '/confirm',
        SUCCESS: {
            PATH: '/success',
        },
    },
};

export default ROUTES;
