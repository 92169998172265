import {FunctionComponent, ReactElement, useEffect, useState} from 'react';

import ROUTES from 'Core/Const/Routes';
import {observer} from 'mobx-react';
import {Navigate} from 'react-router-dom';
import {isTokensExist} from 'Services/LocalStorage';

interface IProps {
    children: ReactElement;
}

const PrivateRoute: FunctionComponent<IProps> = ({children}: IProps) => {
    const [isAuth, setIsAuth] = useState(isTokensExist());

    useEffect(() => {
        const onStorage = () => {
            setIsAuth(isTokensExist());
        };

        window.addEventListener('storage', onStorage);
        return () => window.removeEventListener('storage', onStorage);
    }, []);

    if (isAuth) return children;
    else return <Navigate to={ROUTES.UNAUTHORIZED.LOGIN.PATH} />;
};

export default observer(PrivateRoute);
