import {STABLE_DIFFUSION_TEXT_TO_IMAGE_ENDPOINT} from '../../Core/Const/Endpoints';
import {authClient} from '../axios';
import {TextToImageRequest, TextToImageResponse} from './types';

export default class StableDiffusionAPI {
    textToImage = async (payload: TextToImageRequest, model: string): Promise<TextToImageResponse> => {
        const response = await authClient.post(
            `${STABLE_DIFFUSION_TEXT_TO_IMAGE_ENDPOINT}?model_name=${model}`,
            payload
        );
        return {imageUrls: response.data};
    };
}
