import {FunctionComponent} from 'react';

import ROUTES from 'Core/Const/Routes';
import {observer} from 'mobx-react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import PasswordResetForm from 'UI/Components/Auth/PasswordResetForm';
import PasswordRestoreForm from 'UI/Components/Auth/PasswordRestoreForm';
import PasswordRestoreConfirm from 'UI/Pages/PasswordRestoreConfirm';
import SearchPage from 'UI/Pages/SearchPage';

import {AuthProvider} from '../Services/Auth/context';
import AuthPage from '../UI/Components/Auth/LoginForm';
import PrivateRoute from '../UI/Components/Auth/PrivateRoute/PrivateRoute';
import RegistrationPage from '../UI/Components/Auth/RegistrationForm';
import ProfilePage from '../UI/Pages/ProfilePage';
import PromptPage from '../UI/Pages/PromptPage';
import PromptsPage from '../UI/Pages/PromptsPage';
import RegistrationConfirm from '../UI/Pages/RegistrationConfirm';
import RegistrationConfirmSuccess from '../UI/Pages/RegistrationConfirmSuccess';

const AppRouter: FunctionComponent = () => {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route
                        path={ROUTES.APP.PATH}
                        element={
                            <PrivateRoute>
                                <SearchPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={ROUTES.PROMPTS.PATH}
                        element={
                            <PrivateRoute>
                                <PromptsPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={ROUTES.PROMPTS.PROMPT.PATH}
                        element={
                            <PrivateRoute>
                                <PromptPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={ROUTES.PROFILE.PATH}
                        element={
                            <PrivateRoute>
                                <ProfilePage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={ROUTES.ERROR.PATH}
                        element={
                            <PrivateRoute>
                                <ProfilePage />
                            </PrivateRoute>
                        }
                    />
                    <Route path={ROUTES.UNAUTHORIZED.LOGIN.PATH} element={<AuthPage />} />
                    <Route path={ROUTES.UNAUTHORIZED.REGISTRATION.PATH} element={<RegistrationPage />} />
                    <Route path={ROUTES.UNAUTHORIZED.PASSWORD_RESTORE.PATH} element={<PasswordRestoreForm />} />
                    <Route
                        path={ROUTES.UNAUTHORIZED.PASSWORD_RESTORE_CONFIRM.PATH}
                        element={<PasswordRestoreConfirm />}
                    />
                    <Route path={ROUTES.UNAUTHORIZED.PASSWORD_RESET.PATH} element={<PasswordResetForm />} />
                    <Route path={ROUTES.REGISTRATION_CONFIRM.SUCCESS.PATH} element={<RegistrationConfirmSuccess />} />
                    <Route path={ROUTES.REGISTRATION_CONFIRM.PATH} element={<RegistrationConfirm />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
};

export default observer(AppRouter);
