// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JiMPmtvd0WhLDnfrCLFN{cursor:pointer}.IZuGsnPkLcJhynM4kUxr{color:#9ca3af;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;max-width:150px;padding:4px 10px}.dol_hxi03mmqOFes1eN3{color:#cf1322;margin-left:5px}`, "",{"version":3,"sources":["webpack://./src/UI/Components/PageWrapper/Menu/Chat/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,cAAA,CAGJ,sBACI,aAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,gBAAA,CAGJ,sBACI,aAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatMenuItemWrapper": `JiMPmtvd0WhLDnfrCLFN`,
	"chatMenuItemTitle": `IZuGsnPkLcJhynM4kUxr`,
	"chatMenuItemDeleteBtn": `dol_hxi03mmqOFes1eN3`
};
export default ___CSS_LOADER_EXPORT___;
