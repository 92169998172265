import {FunctionComponent, useEffect, useState} from 'react';

import {Button, Form, Input, Layout, Space} from 'antd';
import {Content} from 'antd/es/layout/layout';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';

import ROUTES from '../../../../Core/Const/Routes';
import {EPageTitle} from '../../../../Core/Enums';
import {useAuth} from '../../../../Services/Auth';
import useStores from '../../../../Stores';
import {FormHeader} from '../FormHeader';
import s from './styles.module.scss';

const RegistrationForm: FunctionComponent = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const {registration} = useAuth();
    const [emailValue, setEmailValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [passwordConfirmValue, setPasswordConfirmValue] = useState('');
    const [loginValue, setLoginValue] = useState('');
    const {setEmail} = useStores().profileStore;

    useEffect(() => {
        document.title = EPageTitle.REGISTRATION;
    }, []);

    const handleOnClickSubmit = async (): Promise<void> => {
        void form.validateFields().then(() => {
            const payload = {
                email: emailValue,
                login: loginValue,
                password: passwordValue,
                confirmed_password: passwordConfirmValue,
            };
            setEmail(emailValue);
            navigate(ROUTES.REGISTRATION_CONFIRM.PATH);
            void registration(payload);
            // navigate(ROUTES.REGISTRATION_CONFIRM.PATH);
        });
    };

    const validateMessages = {
        // eslint-disable-next-line no-template-curly-in-string
        required: 'Поле не может быть пустым',
        types: {
            email: 'Некорректный email',
        },
    };

    return (
        <Layout className={s.authPage}>
            <Content className={s.authContent}>
                <Form
                    className={s.authForm}
                    form={form}
                    layout="vertical"
                    name="nest-messages"
                    validateMessages={validateMessages}
                >
                    <FormHeader />
                    <Form.Item
                        className={s.mailItem}
                        label="Email"
                        name="email"
                        rules={[{type: 'email'}, {required: true}]}
                    >
                        <Input
                            placeholder="email@example.com"
                            value={emailValue}
                            onChange={(e) => setEmailValue(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item className={s.loginItem} label="Логин" name="login" rules={[{required: true}]}>
                        <Input value={loginValue} onChange={(e) => setLoginValue(e.target.value)} />
                    </Form.Item>
                    <Form.Item className={s.passItem} label="Пароль" name="password" rules={[{required: true}]}>
                        <Input.Password value={passwordValue} onChange={(e) => setPasswordValue(e.target.value)} />
                    </Form.Item>
                    {passwordValue ? (
                        <Form.Item
                            className={s.confirmPassItem}
                            label="Подтверждение пароля"
                            name="password-confirm"
                            rules={[{required: true}]}
                        >
                            <Input.Password
                                value={passwordConfirmValue}
                                onChange={(e) => setPasswordConfirmValue(e.target.value)}
                            />
                        </Form.Item>
                    ) : null}
                    <Form.Item className={s.createItem}>
                        <Button
                            className={s.createButton}
                            type="primary"
                            htmlType="submit"
                            onClick={handleOnClickSubmit}
                        >
                            Создать
                        </Button>
                    </Form.Item>
                    <Space className={s.buttonContainer} direction="vertical" align="center">
                        <Button
                            className={s.enterButton}
                            type="link"
                            onClick={() => navigate(ROUTES.UNAUTHORIZED.LOGIN.PATH)}
                        >
                            Войти
                        </Button>
                    </Space>
                </Form>
            </Content>
        </Layout>
    );
};

export default observer(RegistrationForm);
