import React, {FunctionComponent} from 'react';

import {CheckCircleOutlined} from '@ant-design/icons';
import {Card, Row, Space, Typography} from 'antd';
import {observer} from 'mobx-react';

import {useAuth} from '../../../Services/Auth';
import PurchaseButton from '../PurchaseButton';
import s from './styles.module.scss';

interface IProps {
    id: string;
    title: string;
    description: string;
    price: number;
    currency: string;
    items: string[];
    highlighted?: boolean;
}
const PackCard: FunctionComponent<IProps> = ({id, title, description, items, price, currency, highlighted}) => {
    const {getPackPaymentLink} = useAuth();
    return (
        <Card className={s.packCard} bordered>
            <>
                <Row justify="center">
                    <Typography.Text className={s.title} strong>
                        {title}
                    </Typography.Text>
                </Row>
                <Row justify="center">
                    <Space direction="vertical">
                        {/* <Row style={{marginBottom: '20px'}}>{description}</Row> */}

                        {items.map((option, i) => (
                            <Row className={s.optionRow} key={i}>
                                <CheckCircleOutlined className={s.checkIcon} />
                                <Typography.Text>{option}</Typography.Text>
                            </Row>
                        ))}
                    </Space>
                </Row>
                <Row className={s.startRow} justify={'start'}></Row>
                <Row justify="center">
                    <Typography.Text className={s.priceText} strong>
                        {price} {currency}
                    </Typography.Text>

                    <div className={s.purchaseBtn}>
                        <PurchaseButton
                            text="Купить"
                            size="small"
                            handleClick={async () => {
                                const paymentLink = await getPackPaymentLink(id);
                                window.open(paymentLink, '_blank');
                            }}
                        />
                    </div>
                </Row>
            </>
        </Card>
    );
};

export default observer(PackCard);
