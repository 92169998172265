import {FunctionComponent, useEffect} from 'react';

import {Card, Col, Progress, Row, Typography} from 'antd';
import {observer} from 'mobx-react';

import s from './styles.module.scss';
import {useAuth} from '../../../Services/Auth';
import useStores from '../../../Stores';

const ProfileBalances: FunctionComponent = () => {
    const {getAiModelsRequests} = useAuth();
    const {modelsRequests} = useStores().profileStore;

    const colors = ['#87d068', '#ffe58f', '#ffccc7'];

    useEffect(() => {
        void getAiModelsRequests();
    }, []);

    const getColorByRemainder = (remainder: number): string => {
        switch (true) {
            case remainder < 100:
                return colors[2];
            case remainder > 500:
                return colors[0];
            default:
                return colors[1];
        }
    };

    const getPercentsByRemainder = (remainder: number): number => {
        switch (true) {
            case remainder < 100:
                return 10;
            case remainder > 500:
                return 100;
            default:
                return 50;
        }
    };

    return (
        <Row className={s.wrapper} justify="center">
            <Card className={s.balanceCard}>
                <Row className={s.header}>
                    <Col span={8}>
                        <Typography.Text strong>AI</Typography.Text>
                    </Col>
                    <Col span={8}>
                        <Typography.Text strong>Модель</Typography.Text>
                    </Col>
                    <Col span={8}>
                        <Row justify="end">
                            <Typography.Text strong>Осталось запросов</Typography.Text>
                        </Row>
                    </Col>
                </Row>
                {modelsRequests.map((model, index) => (
                    <Row key={index} className={s.modelsRequests} align="bottom">
                        <Col span={8}>
                            <Row className={s.modelTitle} align="bottom">
                                <Typography.Text>{model.ai.title}</Typography.Text>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row className={s.modelType} align="bottom">
                                <Typography.Text>{model.model.title}</Typography.Text>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row className={s.quantity} justify="end">
                                <Row align="bottom" className={s.number}>
                                    <Typography.Text>{model.requests_left}</Typography.Text>
                                </Row>
                                <Progress
                                    size="small"
                                    className={s.progressLine}
                                    percent={getPercentsByRemainder(model.requests_left)}
                                    steps={4}
                                    strokeColor={getColorByRemainder(model.requests_left)}
                                    showInfo={false}
                                />
                            </Row>
                        </Col>
                    </Row>
                ))}
            </Card>
        </Row>
    );
};

export default observer(ProfileBalances);
