import React, {FunctionComponent} from 'react';

import {CheckCircleOutlined, RightOutlined} from '@ant-design/icons';
import {Button, Layout, Row, Space, Typography} from 'antd';
import {Content} from 'antd/es/layout/layout';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';

import ROUTES from '../../Core/Const/Routes';

const RegistrationConfirmSuccess: FunctionComponent = () => {
    const navigate = useNavigate();

    return (
        <Layout style={{height: '100vh'}}>
            <Content style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Space direction="vertical">
                    <Row style={{width: '100%', paddingBottom: 30}} justify="center">
                        <CheckCircleOutlined style={{fontSize: 120, color: 'green'}} />
                    </Row>
                    <Typography.Text>Регистрация успешно подтверждена!</Typography.Text>
                    <Row justify="center">
                        <Button ghost style={{color: '#6C61ed'}} onClick={() => navigate(ROUTES.APP.PATH)}>
                            <RightOutlined />
                            <RightOutlined />
                            <RightOutlined />
                        </Button>
                    </Row>
                    <Row style={{paddingTop: 30}} justify="center">
                        <Typography.Title className="form-header__title">aidisrupt</Typography.Title>
                    </Row>
                </Space>
            </Content>
        </Layout>
    );
};

export default observer(RegistrationConfirmSuccess);
