// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.z2QBWWeXeS3zms9BKu2g{width:96%;max-height:300px}`, "",{"version":3,"sources":["webpack://./src/UI/Components/MessageInput/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,SAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textArea": `z2QBWWeXeS3zms9BKu2g`
};
export default ___CSS_LOADER_EXPORT___;
