import {action, makeAutoObservable, observable} from 'mobx';

import {IBalanceAiModel, IModelOption} from './types';
import Auth from '../../Api/Auth';

export default class ProfileStore {
    @observable
    login = '';

    @observable
    email = '';

    @observable
    password = '';

    @observable
    passwordConfirm = '';

    // TODO: переделать логику, нужно проверять количество оставшихся по каждой модели запросов
    @observable
    hasSubscription = true;

    @observable
    availablePacks: any = [];

    @observable
    modelsOptions: IModelOption[] = [];

    @observable
    modelsRequests: IBalanceAiModel[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    api = new Auth();

    @action
    setLogin = (value: string): void => {
        this.login = value;
    };

    @action
    setEmail = (value: string): void => {
        this.email = value;
    };

    @action
    setPassword = (value: string): void => {
        this.password = value;
    };

    @action
    setAvailablePacks = (packs: any[]): void => {
        this.availablePacks = packs;
    };

    @action
    setSubscriptionStatus = (value: boolean): void => {
        this.hasSubscription = value;
    };

    @action
    setModelsOptions = (models: any): void => {
        this.modelsOptions = models;
    };

    @action
    setAiModelsRequests = (value: any) => {
        this.modelsRequests = value;
    };
}
