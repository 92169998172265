import React, {FunctionComponent, ReactNode, useEffect, useState} from 'react';

import {MenuOutlined, UserOutlined} from '@ant-design/icons';
import {Avatar, Button, Dropdown, Layout, Row} from 'antd';
import {observer} from 'mobx-react';
import {useNavigate, useLocation} from 'react-router-dom';

import ROUTES from '../../../Core/Const/Routes';
import {useAuth} from '../../../Services/Auth';
import {usePrompts} from '../../../Services/Prompts';
import SubscriptionWarningModal from '../SubscriptionWarningModal';
import MenuDrawer from './Menu';
import s from './styles.module.scss';

const {Header, Content, Footer} = Layout;

interface IProps {
    children?: ReactNode;
    footer?: ReactNode;
}

const PageWrapper: FunctionComponent<IProps> = ({children, footer}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {logout} = useAuth();
    const {getHistory} = usePrompts();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

    useEffect(() => {
        void getHistory();
    }, []);

    const menuItemProfileChildren = [
        {
            key: ROUTES.PROFILE.PATH,
            label: 'Управление',
            onClick: () => {
                navigate(ROUTES.PROFILE.PATH);
            },
        },
        {
            key: '/login',
            label: <>Выход</>,
            onClick: () => {
                void logout();
                navigate(ROUTES.UNAUTHORIZED.LOGIN.PATH);
            },
        },
    ];
    // const createMenuItems = (items: any[]): any =>
    //     items.map((item) => ({
    //         key: item.route,
    //         label: item.title,
    //         icon: item.icon ? <item.icon /> : null,
    //         children: item.children ? createMenuItems(item.children) : null,
    //         onClick: item.route === ROUTES.UNAUTHORIZED.LOGIN.PATH ? async () => await logout() : null,
    //     }));

    // const isMenuItemPrivate = (selectedItem: any, items: any[]): boolean =>
    //     items.find((item) => {
    //         if (item.route === selectedItem.key) return item.private;
    //         if (item.children) return isMenuItemPrivate(selectedItem, item.children);
    //         return false;
    //     });

    // const selectedMenuItem = location.pathname;
    // const menuItems = createMenuItems(MENU_ITEMS);
    //
    // const handleClick = (selectedItem: MenuInfo): void => {
    //     const hasPageAccess = hasSubscription || !isMenuItemPrivate(selectedItem, MENU_ITEMS);
    //
    //     if (hasPageAccess) navigate(selectedItem.key);
    //     else setIsWarningModalOpen(true);
    // };

    const handleModal = () => {
        setIsWarningModalOpen(false);
    };

    return (
        <Layout className={s.layout}>
            <MenuDrawer isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />
            <Layout>
                <Header className={s.header}>
                    <Row justify="space-between" align="middle" className={s.headerRow}>
                        <Row align="middle">
                            <MenuOutlined className={s.menuIcon} onClick={() => setIsMenuOpen(true)} />
                            <Button
                                className={s.menuButton}
                                onClick={() => {
                                    location.pathname.includes('/prompt')
                                        ? navigate(ROUTES.APP.PATH)
                                        : navigate(ROUTES.PROMPTS.PATH);
                                }}
                            >
                                {location.pathname.includes('/prompt') ? 'Чат' : 'Шаблоны запросов'}
                            </Button>
                        </Row>
                        <Dropdown menu={{items: menuItemProfileChildren}} trigger={['click']}>
                            <Avatar className={s.avatar}>
                                <UserOutlined />
                            </Avatar>
                        </Dropdown>
                    </Row>
                </Header>
                <Content className={s.content}>
                    <div className={s.container}>{children}</div>
                </Content>
                {footer ? <Footer className={s.footer}>{footer}</Footer> : null}
                <SubscriptionWarningModal open={isWarningModalOpen} onOk={handleModal} onCancel={handleModal} />
            </Layout>
        </Layout>
    );
};

export default observer(PageWrapper);
