import {FunctionComponent, useEffect} from 'react';

import {QuestionCircleOutlined} from '@ant-design/icons';
import {Modal, Form, Input, Button, Tooltip, Row} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {observer} from 'mobx-react';

import s from './styles.module.scss';
import {EPromptDataItemName} from '../../../Core/Enums';
import useStores from '../../../Stores';
import {IPromptField} from '../../../Stores/Prompts/Prompt/types';

interface IProps {
    open: boolean;
    setIsModalOpen: (arg: boolean) => void;
    submitText: string;
    onSubmit: (fields: IPromptField[]) => Promise<void>;
}

const PromptDataModal: FunctionComponent<IProps> = ({open, setIsModalOpen, submitText, onSubmit}: IProps) => {
    const [form] = Form.useForm();
    const {prompt} = useStores().promptStore;

    useEffect(() => {
        form.setFieldsValue({
            [EPromptDataItemName.TITLE]: prompt?.title,
            [EPromptDataItemName.DESCRIPTION]: prompt?.description,
            [EPromptDataItemName.TEMPLATE]: prompt?.template,
        });
    }, [prompt]);

    const promptTemplateTooltipText = `Введите текст, указав в [...] варьируемую часть запроса.
    Например, напишите: "проверь код [вставьте код]"`;

    return (
        <Modal
            title={
                <Row className={s.changeRow} onClick={(e) => e.stopPropagation()}>
                    Редактирование шаблона
                </Row>
            }
            open={open}
            onCancel={(e) => {
                e.stopPropagation();
                setIsModalOpen(false);
            }}
            destroyOnClose
            footer={null}
        >
            <Form form={form} name="prompt-editor" layout="vertical" onClick={(e) => e.stopPropagation()}>
                <Form.Item name={EPromptDataItemName.TITLE} label="Название" className={s.formInputItem}>
                    <Input />
                </Form.Item>
                <Form.Item name={EPromptDataItemName.DESCRIPTION} label="Описание" className={s.descrItem}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name={EPromptDataItemName.TEMPLATE}
                    label={
                        <>
                            Текст запроса
                            <Tooltip title={promptTemplateTooltipText} color="#6c61ed">
                                <QuestionCircleOutlined className={s.questionIcon} />
                            </Tooltip>
                        </>
                    }
                    className={s.queryItem}
                >
                    <TextArea rows={2} />
                </Form.Item>
                {/* {formData?.map((item, index) => ( */}
                {/*    <Form.Item */}
                {/*        {...item} */}
                {/*        label={ */}
                {/*            item.name === EPromptDataItemName.TEMPLATE ? ( */}
                {/*                <> */}
                {/*                    {item.label} */}
                {/*                    <Tooltip title={promptTemplateTooltipText} color="#6c61ed"> */}
                {/*                        <QuestionCircleOutlined style={{marginLeft: '5px', color: '#bdbdbd'}} /> */}
                {/*                    </Tooltip> */}
                {/*                </> */}
                {/*            ) : ( */}
                {/*                item.label */}
                {/*            ) */}
                {/*        } */}
                {/*        className="form-input-item" */}
                {/*        key={index} */}
                {/*    > */}
                {/*        {item.name === EPromptDataItemName.TEMPLATE ? <TextArea rows={2} /> : <Input />} */}
                {/*    </Form.Item> */}
                {/* ))} */}
                <Form.Item className={s.buttonItem}>
                    <Button
                        type="primary"
                        onClick={async () => {
                            const fields: IPromptField[] = Object.values(EPromptDataItemName).map((fieldName) => ({
                                name: fieldName,
                                value: form.getFieldValue(fieldName),
                            }));
                            await onSubmit(fields);
                        }}
                        className={s.submitButton}
                    >
                        {submitText}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(PromptDataModal);
