// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.a1boohrhBL0Y8oeUtLzT{margin:0}.vF4PjCiUJ5_N3JCc2oKX{width:100%;margin-top:20px}.b7VnA6FVVarZBhvjPVWf{width:100%}.FAmpE2rU5X9kn3JWVt9q{margin:0}.i193En7BqmYtsEvygSQ4{margin-left:5px;color:#bdbdbd}.XBmGf4usRIavk1yerhU7{margin:0}.DzRgQBhLXWrrinGuiC43{margin:0}`, "",{"version":3,"sources":["webpack://./src/UI/Components/PromptDataModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,QAAA,CAGJ,sBACI,UAAA,CACA,eAAA,CAGJ,sBACI,UAAA,CAGJ,sBACI,QAAA,CAEJ,sBACI,eAAA,CACA,aAAA,CAEJ,sBACI,QAAA,CAEJ,sBACI,QAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formInputItem": `a1boohrhBL0Y8oeUtLzT`,
	"submitButton": `vF4PjCiUJ5_N3JCc2oKX`,
	"changeRow": `b7VnA6FVVarZBhvjPVWf`,
	"descrItem": `FAmpE2rU5X9kn3JWVt9q`,
	"questionIcon": `i193En7BqmYtsEvygSQ4`,
	"queryItem": `XBmGf4usRIavk1yerhU7`,
	"buttonItem": `DzRgQBhLXWrrinGuiC43`
};
export default ___CSS_LOADER_EXPORT___;
