// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZL1ZGHYOirfwOBhNSP9B{border:none;color:#6c61ed !important}`, "",{"version":3,"sources":["webpack://./src/UI/Components/DialogueReset/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `ZL1ZGHYOirfwOBhNSP9B`
};
export default ___CSS_LOADER_EXPORT___;
