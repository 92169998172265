import {createContext, useContext, useState, ReactElement} from 'react';

import {useNavigate} from 'react-router-dom';

const authContext = createContext({});

interface IProps {
    children: ReactElement;
}

export function AuthProvider({children}: IProps) {
    const [user, setUser] = useState({});
    const navigate = useNavigate();

    const login = (userDetails: any) => {
        setUser(userDetails);
        navigate('/admin/dashboard');
    };

    const logout = () => {
        setUser({});
        navigate('/');
    };

    return <authContext.Provider value={{user, login, logout}}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};
