import {FunctionComponent} from 'react';

import {ConfigProvider} from 'antd';
import AppRouter from 'Core/AppRouter';
import {observer} from 'mobx-react';

import 'UI/Styles/app.scss';

const App: FunctionComponent = () => {
    return (
        <ConfigProvider theme={{token: {colorPrimary: '#6C61ed'}}}>
            <AppRouter />
        </ConfigProvider>
    );
};

export default observer(App);
