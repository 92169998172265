import {ChangeEvent, FC} from 'react';

import {PlusCircleOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Drawer, Input, Row, Space, Typography} from 'antd';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';

import Chats from './Chats';
import s from './styles.module.scss';
import ROUTES from '../../../../Core/Const/Routes';
import {usePrompts} from '../../../../Services/Prompts';
import useStores from '../../../../Stores';
// import PurchaseButton from '../../PurchaseButton';

interface IProps {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}

const MenuDrawer: FC<IProps> = ({isOpen, setIsOpen}: IProps) => {
    const navigate = useNavigate();
    const {resetMessages, setIsLoading} = useStores().searchStore;
    const {setPrompt} = useStores().promptStore;
    const {setCurrentHistoryChatId} = useStores().historyStore;
    const {getHistory} = usePrompts();

    const handleOnChangeChatSearch = async (e: ChangeEvent<HTMLInputElement>) => {
        await getHistory(e.target.value);
    };

    return (
        <Drawer
            open={isOpen}
            placement="left"
            destroyOnClose
            onClose={() => setIsOpen(false)}
            className={s.drawerMenu}
            closeIcon
            width={screen.width <= 500 ? '100%' : 'max-content'}
        >
            <Space direction="vertical" className={s.customHeader}>
                <Row justify={'center'}>
                    <Typography.Title className={s.headerTitle}>AI Rabbit</Typography.Title>
                </Row>

                <Button
                    size="large"
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    className={s.newChatBtn}
                    onClick={() => {
                        setIsLoading(false);
                        resetMessages();
                        setPrompt(null);
                        setCurrentHistoryChatId(null);
                        setIsOpen(false);
                        navigate(ROUTES.APP.PATH);
                    }}
                >
                    Новый чат
                </Button>
                <Input
                    className={s.navSearchInput}
                    size="large"
                    prefix={<SearchOutlined />}
                    onChange={handleOnChangeChatSearch}
                />
            </Space>
            <Row className={s.chatsRow}>
                <Chats setIsMenuOpen={setIsOpen} />
            </Row>
            {/* <PurchaseButton text="Купить" size="large" /> */}
        </Drawer>
    );
};

export default observer(MenuDrawer);
