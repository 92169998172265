// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label::before{content:"" !important}.xnnFg6_rZlklFeqe5LXH{height:100vh}.xnnFg6_rZlklFeqe5LXH *::before,.xnnFg6_rZlklFeqe5LXH *::after{top:0 !important}.KFgCHx7SJnSa62c790da{display:flex;justify-content:center;background:#fff;padding:1%}.o1Nn_iGBAjeEva4Fep6L{padding-top:5%}.o1Nn_iGBAjeEva4Fep6L>*{margin-bottom:0}.K1OR9EDrL6_Zo1tqsROI{margin-top:10px !important;width:100% !important}`, "",{"version":3,"sources":["webpack://./src/UI/Components/Auth/PasswordResetForm/styles.module.scss"],"names":[],"mappings":"AAAA,cACI,qBAAA,CAGJ,sBAKI,YAAA,CAJA,+DAEI,gBAAA,CAKR,sBACI,YAAA,CACA,sBAAA,CACA,eAAA,CACA,UAAA,CAGJ,sBACI,cAAA,CAEA,wBACI,eAAA,CAIR,sBACI,0BAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authPage": `xnnFg6_rZlklFeqe5LXH`,
	"authContent": `KFgCHx7SJnSa62c790da`,
	"authForm": `o1Nn_iGBAjeEva4Fep6L`,
	"changeButton": `K1OR9EDrL6_Zo1tqsROI`
};
export default ___CSS_LOADER_EXPORT___;
