import {FunctionComponent} from 'react';

import {CloseCircleFilled} from '@ant-design/icons';
import {Badge, Col, Row, Tooltip} from 'antd';
import {observer} from 'mobx-react';

import s from './styles.module.scss';
import {usePromptStore} from '../../../Services/Adapters/store';

const PromptReset: FunctionComponent = () => {
    const {prompt, clearPrompt} = usePromptStore();

    const handleClickBadge = (): void => {
        clearPrompt();
    };

    return prompt !== null ? (
        <Badge count={<CloseCircleFilled className={s.badgeCloseIcon} onClick={handleClickBadge} />}>
            <Row className={s.promptTitle} align="middle">
                <Tooltip title={prompt?.title}>
                    <Col className={s.text}>{prompt?.title}</Col>
                </Tooltip>
            </Row>
        </Badge>
    ) : (
        <Row></Row>
    );
};

export default observer(PromptReset);
