import React, {FunctionComponent} from 'react';

import {Button} from 'antd';
import {observer} from 'mobx-react';

import s from './styles.module.scss';

interface IProps {
    text: string;
    size: 'large' | 'small';
    handleClick?: () => any;
}
const PurchaseButton: FunctionComponent<IProps> = ({text, size, handleClick}: IProps) => {
    let cn = 'button';

    if (size === 'large') {
        cn = 'smallButton';
    } else if (size === 'small') {
        cn = 'smallButton';
    }

    return (
        <Button className={s[cn]} size={size} onClick={handleClick}>
            {text}
        </Button>
    );
};

export default observer(PurchaseButton);
