// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ebjZqvpiRuFnT7msMqrh{display:flex;justify-content:center;color:#6c61ed !important}.YoGE4K06AVEhi68uNmrA{display:flex;justify-content:center;color:#6c61ed !important;margin-top:2% !important}`, "",{"version":3,"sources":["webpack://./src/UI/Components/Auth/FormHeader/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,sBAAA,CACA,wBAAA,CAGJ,sBACI,YAAA,CACA,sBAAA,CACA,wBAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `ebjZqvpiRuFnT7msMqrh`,
	"title": `YoGE4K06AVEhi68uNmrA`
};
export default ___CSS_LOADER_EXPORT___;
