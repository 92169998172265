// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tIRL4aHTPX1Zp6DaJriA{height:100vh}.tIRL4aHTPX1Zp6DaJriA *::before,.tIRL4aHTPX1Zp6DaJriA *::after{top:0 !important}.yMVdaEYAEfjRcatTGGHA{display:flex;justify-content:center;background:#fff;padding:1%}.U_0CIXCIjQ3Mw7NwW7yw{padding-top:5%}._8eHkBySHs5YOJaS3hrzp{display:flex;justify-content:center;color:#6c61ed !important}.X80qQKUeB5W2Qq5ap5OQ{display:flex;justify-content:center;color:#6c61ed !important;margin-top:2% !important}.htf81WnNoismtm4yMFVx{margin-bottom:0}.v1snF4nrE1Z4KyEbSeAL{margin-bottom:0}.k1_ZTe8kH4nGSjMvOX9L{color:red !important}.cjPWnMoEvbKcneTIe5eh{width:100%;margin-bottom:0}.WiIpTFJoiHm3kxQRpwMR{margin:10px 0;width:100%}.FhPkvaJ6KhYecXzYoV5y{width:100%}.d8MWvtyeCAe9w1zacSJ_{border:none;color:#6c61ed !important}.PjNG5qJxVgv9qnNUMovV{border:none;color:#6c61ed !important}`, "",{"version":3,"sources":["webpack://./src/UI/Components/Auth/LoginForm/styles.module.scss"],"names":[],"mappings":"AAEA,sBAKI,YAAA,CAJA,+DAEI,gBAAA,CAIR,sBACI,YAAA,CACA,sBAAA,CACA,eAAA,CACA,UAAA,CAEJ,sBACI,cAAA,CAEJ,uBACI,YAAA,CACA,sBAAA,CACA,wBAAA,CAEJ,sBACI,YAAA,CACA,sBAAA,CACA,wBAAA,CACA,wBAAA,CAEJ,sBACI,eAAA,CAEJ,sBACI,eAAA,CAEJ,sBACI,oBAAA,CAEJ,sBACI,UAAA,CACA,eAAA,CAEJ,sBACI,aAAA,CACA,UAAA,CAEJ,sBACI,UAAA,CAEJ,sBACI,WAAA,CACA,wBAAA,CAEJ,sBACI,WAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authPage": `tIRL4aHTPX1Zp6DaJriA`,
	"authContent": `yMVdaEYAEfjRcatTGGHA`,
	"authForm": `U_0CIXCIjQ3Mw7NwW7yw`,
	"typographyFirst": `_8eHkBySHs5YOJaS3hrzp`,
	"typographySecond": `X80qQKUeB5W2Qq5ap5OQ`,
	"formLogin": `htf81WnNoismtm4yMFVx`,
	"formPassword": `v1snF4nrE1Z4KyEbSeAL`,
	"errorList": `k1_ZTe8kH4nGSjMvOX9L`,
	"enterBtnWrapper": `cjPWnMoEvbKcneTIe5eh`,
	"enterBtn": `WiIpTFJoiHm3kxQRpwMR`,
	"space": `FhPkvaJ6KhYecXzYoV5y`,
	"spacePassword": `d8MWvtyeCAe9w1zacSJ_`,
	"spaceRegistration": `PjNG5qJxVgv9qnNUMovV`
};
export default ___CSS_LOADER_EXPORT___;
