// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kZ14IEKWrLrg16unVaih{height:100vh;display:flex}.aYuKn0nG7GPvsk8sjkwL{width:100%;background-color:#fff !important;padding:0}.aYuKn0nG7GPvsk8sjkwL .ant-layout-header{padding-inline:0}.aYuKn0nG7GPvsk8sjkwL .gIs3xsemOj1a7Z3P3k1u{font-size:25px;cursor:pointer;padding-right:20px}.aYuKn0nG7GPvsk8sjkwL .gpyPHxlyFQtPVaSBOeyL{padding:0 10px;height:25px;font-size:12px}.aYuKn0nG7GPvsk8sjkwL .jzMVr9HCjtHJfCFIQrVw{cursor:pointer}.KEVNJTasx9OTiMZIZyBK{width:100%;padding:10px 20px}.nq8TV0ybOYMzW67cqv35{height:100%}.nq8TV0ybOYMzW67cqv35 ._bFXbLcVdS0cbbLeXCZ8{height:100%;background-color:#fff}.Yh61nYbZ5d_mAKOjZHO2{background:#fff !important;padding-bottom:0 !important}@media screen and (max-width: 800px){.Yh61nYbZ5d_mAKOjZHO2{padding-inline:10px}}`, "",{"version":3,"sources":["webpack://./src/UI/Components/PageWrapper/styles.module.scss"],"names":[],"mappings":"AAEA,sBACI,YAAA,CACA,YAAA,CAEJ,sBAII,UAAA,CACA,gCAAA,CACA,SAAA,CALA,yCACI,gBAAA,CAMJ,4CACI,cAAA,CACA,cAAA,CACA,kBAAA,CAEJ,4CACI,cAAA,CACA,WAAA,CACA,cAAA,CAEJ,4CACI,cAAA,CAGR,sBACI,UAAA,CACA,iBAAA,CAEJ,sBACI,WAAA,CAEA,4CACI,WAAA,CACA,qBAAA,CAGR,sBACI,0BAAA,CACA,2BAAA,CAEA,qCAJJ,sBAKQ,mBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `kZ14IEKWrLrg16unVaih`,
	"header": `aYuKn0nG7GPvsk8sjkwL`,
	"menuIcon": `gIs3xsemOj1a7Z3P3k1u`,
	"menuButton": `gpyPHxlyFQtPVaSBOeyL`,
	"avatar": `jzMVr9HCjtHJfCFIQrVw`,
	"headerRow": `KEVNJTasx9OTiMZIZyBK`,
	"content": `nq8TV0ybOYMzW67cqv35`,
	"container": `_bFXbLcVdS0cbbLeXCZ8`,
	"footer": `Yh61nYbZ5d_mAKOjZHO2`
};
export default ___CSS_LOADER_EXPORT___;
