const AUTH_PREF = '/auth';
const OPEN_AI_PREF = '/openai';
const YANDEX_PREF = '/yandex';
const AI_DISRUPT_PREF = '/backend';
const STABLE_DIFFUSION_PREF = '/stable_diffusion';
const BARD_PREF = '/bard';

export const AUTH_LOGIN_ENDPOINT = `${AUTH_PREF}/api/v1/login`;
export const AUTH_LOGOUT_ENDPOINT = `${AUTH_PREF}/api/v1/logout`;
export const AUTH_REGISTRATION_ENDPOINT = `${AUTH_PREF}/api/v1/registration`;
export const AUTH_PASSWORD_RESTORE_ENDPOINT = `${AUTH_PREF}/api/v1/password/restore`;
export const AUTH_PASSWORD_RESET_ENDPOINT = `${AUTH_PREF}/api/v1/password/reset`;
export const AUTH_TOKEN_REFRESH_ENDPOINT = `${AUTH_PREF}/api/v1/token/refresh`;
export const AUTH_USER_DETAIL_ENDPOINT = `${AUTH_PREF}/api/v1/user`;
export const AUTH_VERIFY_ACCESS_TOKEN_ENDPOINT = `${AUTH_PREF}/api/v1/token/verify`;
export const AUTH_GET_AI_MODEL_STATUS = `${AUTH_PREF}/api/v1/user/ai_model/requests`;
export const AUTH_GET_AVAILABLE_PACKS = `${AUTH_PREF}/api/v1/pack`;
export const AUTH_LOGIN_CHANGE = `${AUTH_PREF}/api/v1/login/change`;
export const AUTH_PASSWORD_CHANGE = `${AUTH_PREF}/api/v1/password/change`;
export const AUTH_USER_AI_MODEL = `${AUTH_PREF}/api/v1/user/ai_model`;
export const AUTH_USER_AI_MODEL_REQUESTS = `${AUTH_USER_AI_MODEL}/requests`;

export const OPENAI_SEARCH_ENDPOINT = `${OPEN_AI_PREF}/api/v1/search`;
export const OPENAI_CHAT_ENDPOINT = `${OPEN_AI_PREF}/api/v1/chat`;
export const OPENAI_TEXT_TO_IMAGE_ENDPOINT = `${OPEN_AI_PREF}/api/v1/text2img`;
export const BARD_CHAT_ENDPOINT = `${BARD_PREF}/api/v1/chat`;

export const YANDEX_CHAT_ENDPOINT = `${YANDEX_PREF}/api/v1/completion`;

export const STABLE_DIFFUSION_TEXT_TO_IMAGE_ENDPOINT = `${STABLE_DIFFUSION_PREF}/api/v1/text2img`;

// Получить список промптов (GET), создать промпт (POST)
export const AI_DISRUPT_PROMPTS = `${AI_DISRUPT_PREF}/api/v1/prompt/`;
export const AI_DISRUPT_SAVE_CHAT = `${AI_DISRUPT_PREF}/api/v1/chat/`;
export const AI_DISRUPT_HISTORY_CHAT = `${AI_DISRUPT_PREF}/api/v1/saved_chat/`;
export const getHistoryChatUrl = (id: number): string => `${AI_DISRUPT_PREF}/api/v1/saved_chat/${id}`;
// Получить промпт по id (GET)
export const getPromptUrl = (id: number): string => `${AI_DISRUPT_PROMPTS}${id}`;
// Изменить чат по id (PUT)
export const getChatUrl = (id: string): string => `${AI_DISRUPT_PREF}/api/v1/chat/${id}`;
export const getPackPaymentUrl = (id: string): string => `${AUTH_PREF}/api/v1/pack/${id}/payment`;
