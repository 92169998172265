// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x2mLF95oAfl8U4VWaeJ4{padding:0 10px}.CnUdJfZmlN7rS4bGhYeL{padding:5px}.L61jzzR1OLjG8tSLegoi{background:#e9e9fc;width:auto;margin:0 5px}`, "",{"version":3,"sources":["webpack://./src/UI/Components/PromptTemplate/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,cAAA,CAEJ,sBACI,WAAA,CAEJ,sBACI,kBAAA,CACA,UAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `x2mLF95oAfl8U4VWaeJ4`,
	"messageRow": `CnUdJfZmlN7rS4bGhYeL`,
	"input": `L61jzzR1OLjG8tSLegoi`
};
export default ___CSS_LOADER_EXPORT___;
