// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KGnJWkzm29jpb4hEDpdA{width:96%;min-height:39px;max-height:300px;padding:0 1%}`, "",{"version":3,"sources":["webpack://./src/UI/Components/PromptInput/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,SAAA,CACA,eAAA,CACA,gBAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageRow": `KGnJWkzm29jpb4hEDpdA`
};
export default ___CSS_LOADER_EXPORT___;
