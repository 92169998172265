import OpenaiAPI from '../../Api/Openai';
import {EHTTPStatus} from '../../Core/Enums';
import {useSearchStore} from '../Adapters/store';

// eslint-disable-next-line  @typescript-eslint/explicit-function-return-type
export const useOpenAIImage = () => {
    const api = new OpenaiAPI();
    const {messages, setIsLoading, aiMode} = useSearchStore();

    return {
        async textToImage(): Promise<any> {
            try {
                setIsLoading(true);
                const response = await api.textToImage(
                    {prompt: messages[messages.length - 1].content as string},
                    aiMode
                );
                setIsLoading(false);
                return response;
            } catch (e: any) {
                if (e.data.response.status === EHTTPStatus.UNAUTHORIZED) {
                    setIsLoading(true);
                    const response = await api.textToImage(
                        {prompt: messages[messages.length - 1].content as string},
                        aiMode
                    );
                    setIsLoading(false);
                    return response;
                }
            }
        },
    };
};
