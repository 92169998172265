// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label::before{content:"" !important}.LW1hUfaX8pP8OzrRvYTZ{height:100vh}.LW1hUfaX8pP8OzrRvYTZ *::before,.LW1hUfaX8pP8OzrRvYTZ *::after{top:0 !important}.HAZpZ4ZpSEIs0wrowa7A{display:flex;justify-content:center;background:#fff;padding:1%}.tk7YYgF2wntLcCzPFwcO{padding-top:5%}.O9OfnasdfhvMDjLn3_su{margin-bottom:0}.UD9dSak4qYrmvYksVq0z{width:100%}.RtLfsRUc2ZtqOA4IfXZa{border:none;color:#6c61ed}`, "",{"version":3,"sources":["webpack://./src/UI/Components/Auth/PasswordRestoreForm/styles.module.scss"],"names":[],"mappings":"AAAA,cACI,qBAAA,CAEJ,sBAKI,YAAA,CAJA,+DAEI,gBAAA,CAIR,sBACI,YAAA,CACA,sBAAA,CACA,eAAA,CACA,UAAA,CAEJ,sBACI,cAAA,CAEJ,sBACI,eAAA,CAEJ,sBACI,UAAA,CAEJ,sBACI,WAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authPage": `LW1hUfaX8pP8OzrRvYTZ`,
	"authContent": `HAZpZ4ZpSEIs0wrowa7A`,
	"authForm": `tk7YYgF2wntLcCzPFwcO`,
	"mailItem": `O9OfnasdfhvMDjLn3_su`,
	"buttonContainer": `UD9dSak4qYrmvYksVq0z`,
	"button": `RtLfsRUc2ZtqOA4IfXZa`
};
export default ___CSS_LOADER_EXPORT___;
