// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LTD4koTNiwrcJli9gF0p{width:100%;height:100%;padding:30px 0}.Yg76lgrY6qYb4LIQfvh4{color:#fff !important}.dfxZeiosMfWznmmhVPTU{max-height:100%;overflow:auto}.dfxZeiosMfWznmmhVPTU &::-webkit-scrollbar{display:none}`, "",{"version":3,"sources":["webpack://./src/UI/Components/PageWrapper/Menu/Chats/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CACA,cAAA,CAGJ,sBACI,qBAAA,CAGJ,sBACI,eAAA,CACA,aAAA,CAEA,2CACI,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recentlyChatsWrapper": `LTD4koTNiwrcJli9gF0p`,
	"recentlyText": `Yg76lgrY6qYb4LIQfvh4`,
	"recentlyChats": `dfxZeiosMfWznmmhVPTU`
};
export default ___CSS_LOADER_EXPORT___;
