// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mK3HfzskJfxbFN237917,.aBZsKcIFxi2N4rLmsJcD{display:block;width:100%;padding:1rem 5%}.mK3HfzskJfxbFN237917{background:#fff}.aBZsKcIFxi2N4rLmsJcD{background:#eef2fe}.K4kYO3AmcX_Of1cKYsDR{width:90%}.yQnhvoECdXEevjdxm_S2{width:100%}.YWiqodQrXgoKaDf7bEAg{margin-left:10px;padding:5px 10px;border-radius:calc(1rem + 10px);background-color:#ccd5d8}.uoYPmLUPFWrVuFsCiAQR{overflow:auto}.lYen3XGMKR_YMiC9E9j8{color:#8b8b8b;font-size:16px}`, "",{"version":3,"sources":["webpack://./src/UI/Components/Message/styles.module.scss"],"names":[],"mappings":"AAGA,4CAEI,aAAA,CACA,UAAA,CACA,eAAA,CAGJ,sBACI,eAAA,CAGJ,sBACI,kBAAA,CAGJ,sBACI,SAAA,CAEJ,sBACI,UAAA,CAEJ,sBACI,gBAAA,CACA,gBAAA,CACA,+BAAA,CACA,wBAAA,CAEJ,sBACI,aAAA,CAEJ,sBACI,aAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userContainer": `mK3HfzskJfxbFN237917`,
	"notUserContainer": `aBZsKcIFxi2N4rLmsJcD`,
	"innerRow": `K4kYO3AmcX_Of1cKYsDR`,
	"avatarRow": `yQnhvoECdXEevjdxm_S2`,
	"modelTitle": `YWiqodQrXgoKaDf7bEAg`,
	"messageCol": `uoYPmLUPFWrVuFsCiAQR`,
	"messageActBtn": `lYen3XGMKR_YMiC9E9j8`
};
export default ___CSS_LOADER_EXPORT___;
