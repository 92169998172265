// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UV_5pnIedGN8g9f0vzp_{min-width:300px}@media(max-width: 800px){.UV_5pnIedGN8g9f0vzp_{width:100%}}`, "",{"version":3,"sources":["webpack://./src/UI/Components/ProfileSettingsInfo/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,eAAA,CAEA,yBAHJ,sBAIQ,UAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `UV_5pnIedGN8g9f0vzp_`
};
export default ___CSS_LOADER_EXPORT___;
