import memoize from 'memoizee';

import Auth from './Auth';
import {removeAuthTokens, setAuthTokens} from '../Services/LocalStorage';

const refreshToken = async () => {
    await new Auth()
        .refreshTokens()
        .then((response) => {
            setAuthTokens(response.data.access_token, response.data.refresh_token);
        })
        .catch(() => {
            removeAuthTokens();
            window.dispatchEvent(new Event('storage'));
        });
};

const maxAge = 10000;
const memoizedRefreshToken = memoize(refreshToken, {maxAge});

export default memoizedRefreshToken;
