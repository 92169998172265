import {createContext, useContext} from 'react';

import profileStore from './Profile';
import historyStore from './Prompts/History';
import promptStore from './Prompts/Prompt';
import promptsListStore from './Prompts/PromptsList';
import searchStore from './Search';

export const storesContext = createContext({
    searchStore,
    promptStore,
    promptsListStore,
    profileStore,
    historyStore,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useStores = () => useContext(storesContext);
export default useStores;
