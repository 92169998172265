import {FC} from 'react';

import {Col, Row, Typography} from 'antd';
import {observer} from 'mobx-react';
import useStores from 'Stores';

import ChatMenuItem from '../Chat';
import s from './styles.module.scss';

interface IProps {
    setIsMenuOpen: (arg: boolean) => void;
}

const ChatsWrapper: FC<IProps> = ({setIsMenuOpen}: IProps) => {
    const {chats} = useStores().historyStore;

    return (
        <Col className={s.recentlyChatsWrapper}>
            <Typography.Text strong className={s.recentlyText}>
                Недавно
            </Typography.Text>
            <Row className={s.recentlyChats}>
                <Col>
                    {chats.map((chat, i) => (
                        <ChatMenuItem chat={chat} setIsMenuOpen={setIsMenuOpen} key={i} />
                    ))}
                </Col>
            </Row>
        </Col>
    );
};

export default observer(ChatsWrapper);
