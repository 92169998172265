const API_GATEWAY_SERVICE_URL = process.env.REACT_APP_API_GATEWAY_SERVER_URL;
const AUTH_SERVICE_URL = process.env.REACT_APP_AUTH_SERVER_URL;
const OPENAI_SERVICE_URL = process.env.REACT_APP_OPENAI_SEARCH_SERVER_URL;
const STABLE_DIFFUSION_SERVICE_URL = process.env.REACT_APP_STABLE_DIFFUSION_SERVER_URL;
const BARD_SERVICE_URL = process.env.REACT_APP_BARD_SERVER_URL;
const AI_DISRUPT_SERVICE_URL = process.env.REACT_APP_AI_DISRUPT_SERVER_URL;
const DEBUG = process.env.DEBUG;

export {
    OPENAI_SERVICE_URL,
    STABLE_DIFFUSION_SERVICE_URL,
    BARD_SERVICE_URL,
    AI_DISRUPT_SERVICE_URL,
    AUTH_SERVICE_URL,
    API_GATEWAY_SERVICE_URL,
    DEBUG,
};
