// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media(max-width: 800px){.PSC_Vsl1Fv_ILGHSKBbJ{width:100%;padding-inline:20px 10px}}@media(max-width: 800px){.ant-drawer-content-wrapper{width:100%}}`, "",{"version":3,"sources":["webpack://./src/UI/Components/ProfileSettings/styles.module.scss"],"names":[],"mappings":"AACI,yBADJ,sBAEQ,UAAA,CACA,wBAAA,CAAA,CAKJ,yBADJ,4BAEQ,UAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PSC_Vsl1Fv_ILGHSKBbJ`
};
export default ___CSS_LOADER_EXPORT___;
