// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Xb1ckxF_0EF3IMpU9C4F{height:100%;border:1px solid #6c61ed;border-radius:8px}@media screen and (max-width: 800px){.Xb1ckxF_0EF3IMpU9C4F{width:max-content}}.FIg431D4g2Rcd5kcw7WT{font-size:20px;margin-bottom:10px}.dY0vHUApra062sWq7JaU{margin-bottom:15px}.ZxwcASPZnVWwfrnFckZQ{font-size:20px;margin-right:4px;color:#6c61ed}._T7SXfpUhdeJUTCkKpqi{margin-bottom:20px}.Xk0c1pU7oBooRBzh0eov{font-size:20px;padding-bottom:20px}.PP4twws3iR2kSPdmSgm3{width:80%}`, "",{"version":3,"sources":["webpack://./src/UI/Components/PackCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,wBAAA,CACA,iBAAA,CAEA,qCALJ,sBAMQ,iBAAA,CAAA,CAIR,sBACI,cAAA,CACA,kBAAA,CAGJ,sBACI,kBAAA,CAEJ,sBACI,cAAA,CACA,gBAAA,CACA,aAAA,CAEJ,sBACI,kBAAA,CAEJ,sBACI,cAAA,CACA,mBAAA,CAEJ,sBACI,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"packCard": `Xb1ckxF_0EF3IMpU9C4F`,
	"title": `FIg431D4g2Rcd5kcw7WT`,
	"optionRow": `dY0vHUApra062sWq7JaU`,
	"checkIcon": `ZxwcASPZnVWwfrnFckZQ`,
	"startRow": `_T7SXfpUhdeJUTCkKpqi`,
	"priceText": `Xk0c1pU7oBooRBzh0eov`,
	"purchaseBtn": `PP4twws3iR2kSPdmSgm3`
};
export default ___CSS_LOADER_EXPORT___;
