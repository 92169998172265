import {FunctionComponent, ReactNode, useEffect} from 'react';

import {PlusOutlined, SendOutlined} from '@ant-design/icons';
import {Button, Image, Row, Select, Typography, Alert, Tooltip} from 'antd';
import ROUTES from 'Core/Const/Routes';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router';

import s from './styles.module.scss';
import {AnswerMode} from '../../../Core/Enums';
import {useSearchStore} from '../../../Services/Adapters/store';
import useStores from '../../../Stores';

interface IProps {
    children?: ReactNode;
    onClickSend: () => Promise<void>;
    resetControl: ReactNode;
}

const InputWrapper: FunctionComponent<IProps> = ({children, onClickSend, resetControl}: IProps) => {
    const {isLoading, aiMode, setAiMode} = useSearchStore();
    const {modelsOptions} = useStores().profileStore;
    // const {setIsCreatePromptModalVisible} = usePromptStore();
    const {resetMessages, setIsLoading} = useStores().searchStore;
    const {setPrompt} = useStores().promptStore;
    const {setCurrentHistoryChatId} = useStores().historyStore;
    const navigate = useNavigate();

    // const getAiModelsGroups = () => {
    //     const aiModelsGroups: Array<{
    //         id: string;
    //         name: string;
    //         models: Array<{id: string; name: string; title: string}>;
    //     }> = [];

    //     modelsOptions.forEach(({id, name, title, ai}) => {
    //         if (!aiModelsGroups.length) aiModelsGroups.push({id: ai.id, name: ai.title, models: [{id, name, title}]});
    //         else {
    //             const existingAiModelGroupIndex = aiModelsGroups.findIndex(({id: groupId}) => ai.id === groupId);

    //             if (existingAiModelGroupIndex !== -1)
    //                 aiModelsGroups[existingAiModelGroupIndex]?.models.push({id: ai.id, name, title});
    //             else {
    //                 aiModelsGroups.push({id: ai.id, name: ai.title, models: [{id, name, title}]});
    //             }
    //         }
    //     });

    //     return aiModelsGroups;
    // };

    // const menuGroups = getAiModelsGroups();
    // const menuGroupsOptions = menuGroups.map(({name, models}) => ({
    //     label: name,
    //     options: models.map((model) => ({
    //         label: model.title,
    //         value: model.name,
    //     })),
    // }));

    type TModelName =
        | 'o1-preview'
        | 'o1-mini'
        | 'gpt-4'
        | 'gpt-4-1106-preview'
        | 'gpt-4o'
        | 'gpt-4o-mini'
        | 'gpt-3.5-turbo'
        | 'dall-e-3'
        | 'dall-e-2'
        | 'yandexgpt-lite'
        | 'yandexgpt'
        | 'summarization'
        | 'text-to-image';

    const renderModelTitle = (title: string, name: TModelName) => {
        const mappedImg = {
            'o1-preview': require('Assets/svg/o1-preview.svg').default,
            'o1-mini': require('Assets/svg/o1-mini.svg').default,
            'gpt-4': require('Assets/svg/gpt-4.svg').default,
            'gpt-4-1106-preview': require('Assets/svg/gpt-4-1106-preview.svg').default,
            'gpt-4o': require('Assets/svg/gpt-4o.svg').default,
            'gpt-4o-mini': require('Assets/svg/gpt-4o-mini.svg').default,
            'gpt-3.5-turbo': require('Assets/svg/gpt-3.5-turbo.svg').default,
            'dall-e-3': require('Assets/svg/dall-e-3.svg').default,
            'dall-e-2': require('Assets/svg/dall-e-2.svg').default,
            'yandexgpt-lite': require('Assets/svg/yandexgpt-lite.svg').default,
            yandexgpt: require('Assets/svg/yandexgpt.svg').default,
            summarization: require('Assets/svg/yandex-sum.svg').default,
            'text-to-image': require('Assets/svg/text-to-image.svg').default,
        };

        return (
            <>
                <Image className={s.modelsOptionsImage} src={mappedImg[name]} preview={false} />
                <Typography.Text className={s.modelsOptionsText}>{title}</Typography.Text>
            </>
        );
    };

    const mappedModelsOptions = modelsOptions.map(({name, title}) => ({
        label: renderModelTitle(title, name),
        value: name,
    }));

    const defineDefaultModel = (availableModels: TModelName[]): TModelName => {
        const modelPriority: TModelName[] = [
            AnswerMode.GPT_4_O_MINI,
            AnswerMode.GPT_4_O,
            AnswerMode.O1_PREVIEW,
            AnswerMode.O1_MINI,
            AnswerMode.GPT_4_PRO,
            AnswerMode.GPT_4,
            AnswerMode.GPT_3_5,
            AnswerMode.YANDEX_GPT_PRO,
            AnswerMode.YANDEX_GPT_LITE,
            AnswerMode.YANDEX_GPT_SUM,
            AnswerMode.DALLE_3,
            AnswerMode.DALLE_2,
            AnswerMode.TEXT_TO_IMAGE,
        ];

        const selectedModel = modelPriority.find((model) => availableModels.includes(model));
        return selectedModel ?? AnswerMode.GPT_4_O_MINI;
    };

    useEffect(() => {
        const availableModels = modelsOptions.map(({name}) => name as TModelName);
        const defaultModel = defineDefaultModel(availableModels);
        setAiMode(defaultModel);
    }, []);

    const handleSendClick = async () => {
        const availableModels = modelsOptions.map(({name}) => name as TModelName);
        if (!availableModels.includes(aiMode as TModelName)) {
            alert('Вам необходимо пополнить баланс запросов к моделям.');
            return;
        }
        await onClickSend();
    };

    const renderAiModeSelector = (): React.ReactElement => {
        if (mappedModelsOptions.length > 0) {
            return (
                <Select
                    value={aiMode}
                    variant="borderless"
                    suffixIcon={null}
                    className={s.modelSelector}
                    dropdownStyle={{width: 200}}
                    onChange={(value) => setAiMode(value)}
                    options={mappedModelsOptions}
                />
            );
        } else {
            return (
                <Tooltip title="Для продолжения работы Вам необходимо пополнить баланс запросов к моделям">
                    <Alert message="Нет активного плана" type="info" showIcon />
                </Tooltip>
            );
        }
    };

    return (
        <Row className={s.messageInput}>
            <Row className={s.controlsWrapper}>
                <Row className={s.btnBlock} justify="space-between" align="middle">
                    {renderAiModeSelector()}
                    {resetControl}
                    <Button
                        type="link"
                        icon={<PlusOutlined />}
                        className={s.newChatBtn}
                        onClick={() => {
                            setIsLoading(false);
                            resetMessages();
                            setPrompt(null);
                            setCurrentHistoryChatId(null);
                            navigate(ROUTES.APP.PATH);
                        }}
                    >
                        новый чат
                    </Button>
                </Row>
                <Row className={s.inputWrapper} wrap={false} align="middle">
                    {children}
                    <Row className={s.sendBtnWrapper} justify="center">
                        <Button
                            loading={isLoading}
                            className={s.sendBtn}
                            type="text"
                            icon={<SendOutlined className={s.sendBtnIcon} />}
                            onClick={handleSendClick}
                        />
                    </Row>
                </Row>
            </Row>
        </Row>
    );
};

export default observer(InputWrapper);
