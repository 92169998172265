// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label::before{content:"" !important}.r09Nzq1gaOV5VjE1b0Qw{height:100vh}.r09Nzq1gaOV5VjE1b0Qw *::before,.r09Nzq1gaOV5VjE1b0Qw *::after{top:0 !important}.jDzKg70mI5fENuCmrFfX{display:flex;justify-content:center;background:#fff;padding:1%}.aWiecZ9C863O6Tfrwsj3{padding-top:5%}.le72JONrRolU0lD_DcPR{margin-bottom:0}.YPsqWoktTiUYMmwVQoyA{margin-bottom:0}.r4b5820rMQgncH0lp0Co{margin-bottom:0}.nUgFh_KFoA4_3TQKRC4k{margin-bottom:0}.Vyz9e3lKYgyUAmus4Rww{margin-bottom:0}.vplN8eaPrg1Cj5okPKhg{margin-top:10px;width:100%}.UdTzvcxzB0pR0xkwJdlc{width:100%}.vncLd7WMjb3kbnCUGHNV{border:none;color:#6c61ed}`, "",{"version":3,"sources":["webpack://./src/UI/Components/Auth/RegistrationForm/styles.module.scss"],"names":[],"mappings":"AAAA,cACI,qBAAA,CAEJ,sBAKI,YAAA,CAJA,+DAEI,gBAAA,CAIR,sBACI,YAAA,CACA,sBAAA,CACA,eAAA,CACA,UAAA,CAEJ,sBACI,cAAA,CAEJ,sBACI,eAAA,CAEJ,sBACI,eAAA,CAEJ,sBACI,eAAA,CAEJ,sBACI,eAAA,CAEJ,sBACI,eAAA,CAEJ,sBACI,eAAA,CACA,UAAA,CAEJ,sBACI,UAAA,CAEJ,sBACI,WAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authPage": `r09Nzq1gaOV5VjE1b0Qw`,
	"authContent": `jDzKg70mI5fENuCmrFfX`,
	"authForm": `aWiecZ9C863O6Tfrwsj3`,
	"mailItem": `le72JONrRolU0lD_DcPR`,
	"loginItem": `YPsqWoktTiUYMmwVQoyA`,
	"passItem": `r4b5820rMQgncH0lp0Co`,
	"confirmPassItem": `nUgFh_KFoA4_3TQKRC4k`,
	"createItem": `Vyz9e3lKYgyUAmus4Rww`,
	"createButton": `vplN8eaPrg1Cj5okPKhg`,
	"buttonContainer": `UdTzvcxzB0pR0xkwJdlc`,
	"enterButton": `vncLd7WMjb3kbnCUGHNV`
};
export default ___CSS_LOADER_EXPORT___;
