// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BCDYXj5axQq74ZEgc7_Z{min-width:300px}@media(max-width: 800px){.BCDYXj5axQq74ZEgc7_Z{width:100%;margin-top:20px}}.SCsqjodb8dHflW1C6REP{margin-bottom:0;width:100%}.ZHQvTP0t2INu14t1a3Gc{margin-bottom:0;width:100%}.CAnzxuqSZeFgkY54SPJj{width:100%;margin-top:20px}`, "",{"version":3,"sources":["webpack://./src/UI/Components/ProfileSettingsChangePassword/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,eAAA,CAEA,yBAHJ,sBAIQ,UAAA,CACA,eAAA,CAAA,CAGR,sBACI,eAAA,CACA,UAAA,CAEJ,sBACI,eAAA,CACA,UAAA,CAEJ,sBACI,UAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `BCDYXj5axQq74ZEgc7_Z`,
	"passItem": `SCsqjodb8dHflW1C6REP`,
	"confirmPassItem": `ZHQvTP0t2INu14t1a3Gc`,
	"button": `CAnzxuqSZeFgkY54SPJj`
};
export default ___CSS_LOADER_EXPORT___;
