// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Q93zyZgraraKPPpdxA2n .g9peeAInfNAeo_zURcr6,.Q93zyZgraraKPPpdxA2n .HtHTtoW4CyrEPW_P3Ff4,.Q93zyZgraraKPPpdxA2n .mPYI7UmW9XCEoNeBw13R{padding-bottom:10px}.Q93zyZgraraKPPpdxA2n .hn00yfK8GsRZbBq9KCfS{margin-left:5px;color:#000}`, "",{"version":3,"sources":["webpack://./src/UI/Components/CreatePromptModal/styles.module.scss"],"names":[],"mappings":"AACI,oIAGI,mBAAA,CAGJ,4CACI,eAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `Q93zyZgraraKPPpdxA2n`,
	"titleRow": `g9peeAInfNAeo_zURcr6`,
	"descriptionRow": `HtHTtoW4CyrEPW_P3Ff4`,
	"queryRow": `mPYI7UmW9XCEoNeBw13R`,
	"questionIcon": `hn00yfK8GsRZbBq9KCfS`
};
export default ___CSS_LOADER_EXPORT___;
