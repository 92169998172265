export const getAccessToken = () => localStorage.getItem('x-access-token');

export const getRefreshToken = () => localStorage.getItem('x-refresh-token');

export const getAccessTokenHeader = () => `Bearer ${getAccessToken()}`;

export const getRefreshTokenHeader = () => `Bearer ${getRefreshToken()}`;

export const setAuthTokens = (accessToken: string, refreshToken: string) => {
    localStorage.setItem('x-access-token', accessToken);
    localStorage.setItem('x-refresh-token', refreshToken);
};

export const removeAuthTokens = () => {
    localStorage.removeItem('x-access-token');
    localStorage.removeItem('x-refresh-token');
};

export const isTokensExist = () => getAccessToken() && getRefreshToken();

export const isAccessTokenExpired = () => {
    const accessToken = getAccessToken();

    if (accessToken) {
        const arrayToken = accessToken.split('.');
        const tokenPayloadPosition = 1;
        const tokenData = JSON.parse(window.atob(arrayToken[tokenPayloadPosition]));
        const milliseconds = 1000;
        return Date.now() >= tokenData.exp * milliseconds;
    }

    return false;
};
