import {FunctionComponent} from 'react';

import {observer} from 'mobx-react';

import {usePrompts} from '../../../Services/Prompts';
import useStores from '../../../Stores';
import {IPromptField} from '../../../Stores/Prompts/Prompt/types';
import PromptDataModal from '../PromptDataModal';

interface IProps {
    promptId: number;
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}

const PromptEditor: FunctionComponent<IProps> = ({promptId, isOpen, setIsOpen}: IProps) => {
    const {getPrompt, getPrompts, updatePrompt} = usePrompts();
    const {prompt, setPromptFields} = useStores().promptStore;

    const handleOnClickSubmit = async (fields: IPromptField[]) => {
        setPromptFields(fields);

        if (prompt?.id) {
            await updatePrompt(prompt?.id);
            await getPrompts();
            await getPrompt(promptId);
        }

        setIsOpen(false);
    };

    return (
        <PromptDataModal
            open={isOpen}
            setIsModalOpen={setIsOpen}
            submitText="Изменить"
            onSubmit={handleOnClickSubmit}
        />
    );
};

export default observer(PromptEditor);
