import {FunctionComponent, useEffect, useState} from 'react';

import {LoginOutlined} from '@ant-design/icons';
import {Button, Form, Input, Layout, Space, Typography} from 'antd';
import {Content} from 'antd/es/layout/layout';
import {AxiosError} from 'axios';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';

import s from './styles.module.scss';
import ROUTES from '../../../../Core/Const/Routes';
import {EHTTPStatus, EPageTitle} from '../../../../Core/Enums';
import {useAuth} from '../../../../Services/Auth';

interface IAuthData {
    login: string;
    password: string;
}

const AuthForm: FunctionComponent = () => {
    const navigate = useNavigate();
    const {login} = useAuth();
    const [form] = Form.useForm();
    const [authErrors, setAuthErrors] = useState<string[]>([]);

    useEffect(() => {
        document.title = EPageTitle.LOGIN;
    }, []);

    const handleSignIn = async (authData: IAuthData) => {
        const payload = {login: authData.login, password: authData.password};
        try {
            await login(payload);
            navigate(ROUTES.APP.PATH);
        } catch (e) {
            const err = e as AxiosError;

            if (err.response?.status === EHTTPStatus.NOT_FOUND) setAuthErrors(['Пользователь не найден']);

            if (err.response?.status === EHTTPStatus.UNAUTHORIZED) setAuthErrors(['Неверный логин или пароль']);
        }
    };

    const onFinish = async (authData: IAuthData) => {
        await form.validateFields();
        await handleSignIn(authData);
    };

    const validateMessages = {
        // eslint-disable-next-line no-template-curly-in-string
        required: '${label} не может быть пустым',
    };

    return (
        <Layout className={s.authPage}>
            <Content className={s.authContent}>
                <Form
                    className={s.authForm}
                    layout="vertical"
                    validateMessages={validateMessages}
                    onFinish={onFinish}
                    onValuesChange={() => {
                        setAuthErrors([]);
                    }}
                >
                    <Typography.Title className={s.typographyFirst}>
                        <LoginOutlined />
                    </Typography.Title>
                    <Typography.Title className={s.typographySecond}>AI Rabbit</Typography.Title>
                    <Form.Item className={s.formLogin} label="Логин" name="login" rules={[{required: true}]}>
                        <Input />
                    </Form.Item>
                    <Form.Item className={s.formPassword} label="Пароль" name="password" rules={[{required: true}]}>
                        <Input.Password />
                    </Form.Item>

                    <div className={s.errorList}>
                        <Form.ErrorList errors={authErrors} />
                    </div>

                    <Form.Item className={s.enterBtnWrapper}>
                        <Button className={s.enterBtn} type="primary" htmlType="submit">
                            Войти
                        </Button>
                    </Form.Item>
                    <Space className={s.space} direction="vertical" align="center">
                        <Button
                            type="link"
                            className={s.spacePassword}
                            onClick={() => navigate(ROUTES.UNAUTHORIZED.PASSWORD_RESTORE.PATH)}
                        >
                            Забыли пароль?
                        </Button>
                        <Button
                            type="link"
                            className={s.spaceRegistration}
                            onClick={() => navigate(ROUTES.UNAUTHORIZED.REGISTRATION.PATH)}
                        >
                            Регистрация
                        </Button>
                    </Space>
                </Form>
            </Content>
        </Layout>
    );
};

export default observer(AuthForm);
