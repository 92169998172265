// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fLTwtZ0MzWZAksZIuBmi{width:100%;height:100%;display:flex;align-items:center;justify-content:center}.ABBbzZHN_N0V29wCmpWq{width:100%;display:flex;justify-content:center;align-items:center;padding:8px 0;background-color:#fff;color:#595959;border-top:1px solid #c2c2c2;border-bottom:1px solid #c2c2c2;margin:16px 0}.h3QbXfAxsft9PplwwYIX{height:100%}.e5KZ0W02bWVCc0zf3Zy3{width:100%;height:100%;overflow:auto}.KroArzsNb87Kjx6p8z8j{color:#6c61ed}.gQ1nzcPQou3ZkVd_haey{display:block;width:100%;background:#eef2fe;padding:1rem 5%}.j3bAz6VE0lwGHc6fu9X8{margin:1rem 1rem 1rem 0}.eodVSpZP0KTeDLJBj6w0{color:#a59df4;font-style:italic}.YmXARX7vFOXgB7ggibka{margin-right:10px;color:#a59df4}`, "",{"version":3,"sources":["webpack://./src/UI/Components/MessageContainer/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGJ,sBACI,UAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,aAAA,CACA,qBAAA,CACA,aAAA,CACA,4BAAA,CACA,+BAAA,CACA,aAAA,CAGJ,sBACI,WAAA,CAEJ,sBACI,UAAA,CACA,WAAA,CACA,aAAA,CAEJ,sBACI,aAAA,CAEJ,sBACI,aAAA,CACA,UAAA,CACA,kBAAA,CACA,eAAA,CAEJ,sBACI,uBAAA,CAEJ,sBACI,aAAA,CACA,iBAAA,CAEJ,sBACI,iBAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyContainer": `fLTwtZ0MzWZAksZIuBmi`,
	"endOfContext": `ABBbzZHN_N0V29wCmpWq`,
	"spaceCompact": `h3QbXfAxsft9PplwwYIX`,
	"spaceContainer": `e5KZ0W02bWVCc0zf3Zy3`,
	"emptyImg": `KroArzsNb87Kjx6p8z8j`,
	"loadingRow": `gQ1nzcPQou3ZkVd_haey`,
	"avatar": `j3bAz6VE0lwGHc6fu9X8`,
	"withAvatarRow": `eodVSpZP0KTeDLJBj6w0`,
	"loadingOutlined": `YmXARX7vFOXgB7ggibka`
};
export default ___CSS_LOADER_EXPORT___;
