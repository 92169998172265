// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ghuqo_kc2HnrzBu8FMIn{margin-bottom:4px}.qXiuCBLlvS66xXX7oLTL{margin-left:5px;color:#6c61ed}.kAdnmS1J0MDwniXEOFHh{width:max-content;min-width:100px;border:none}.kAdnmS1J0MDwniXEOFHh .EnSfOfKajBGBL0yPLjRq{color:#6c61ed}.kAdnmS1J0MDwniXEOFHh .mUVP06zUn6D6CKV0zv9A{color:#6c61ed}.GJy0haCz3XG3tC6fcClv{margin-top:24px;padding:0 1rem}@media screen and (max-width: 800px){.GJy0haCz3XG3tC6fcClv{padding:0 10px}}.nmSjRKCCVEMFbUeUfB6O{width:100%;border-radius:10px;margin-bottom:24px}.FUJ4zHNXDBAGQn03Gcyz{width:100%;padding:1% 2%}.M_lRNRYL8rKVv9lPv0gf{color:#6c61ed}.yIDnKfODt6ANY2TDFVS_{width:100%;min-height:50px;border:1px solid #6c61ed;border-radius:40px;background-color:#fff;overflow:auto;padding:0 10px}.TBVCDw5YCRwfS_1y8Nll{width:4%;align-items:center}.He5n1JpDxAJm43LmEN9g{display:flex;justify-content:center;align-items:center;border:none;background:none;color:#6c61ed}.Hc5QZq92ObK7XcHL5Ire{font-size:20px !important}`, "",{"version":3,"sources":["webpack://./src/UI/Components/InputWrapper/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CAEJ,sBACI,eAAA,CACA,aAAA,CAEJ,sBACI,iBAAA,CACA,eAAA,CACA,WAAA,CACA,4CACI,aAAA,CAEJ,4CACI,aAAA,CAGR,sBACI,eAAA,CACA,cAAA,CAEA,qCAJJ,sBAKQ,cAAA,CAAA,CAGR,sBACI,UAAA,CACA,kBAAA,CACA,kBAAA,CAEJ,sBACI,UAAA,CACA,aAAA,CAEJ,sBACI,aAAA,CAEJ,sBACI,UAAA,CACA,eAAA,CACA,wBAAA,CACA,kBAAA,CACA,qBAAA,CACA,aAAA,CACA,cAAA,CAEJ,sBACI,QAAA,CACA,kBAAA,CAEJ,sBACI,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,eAAA,CACA,aAAA,CAEJ,sBACI,yBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modelsOptionsImage": `Ghuqo_kc2HnrzBu8FMIn`,
	"modelsOptionsText": `qXiuCBLlvS66xXX7oLTL`,
	"modelSelector": `kAdnmS1J0MDwniXEOFHh`,
	"ant-select-selection-item": `EnSfOfKajBGBL0yPLjRq`,
	"ant-select-arrow": `mUVP06zUn6D6CKV0zv9A`,
	"messageInput": `GJy0haCz3XG3tC6fcClv`,
	"controlsWrapper": `nmSjRKCCVEMFbUeUfB6O`,
	"btnBlock": `FUJ4zHNXDBAGQn03Gcyz`,
	"newChatBtn": `M_lRNRYL8rKVv9lPv0gf`,
	"inputWrapper": `yIDnKfODt6ANY2TDFVS_`,
	"sendBtnWrapper": `TBVCDw5YCRwfS_1y8Nll`,
	"sendBtn": `He5n1JpDxAJm43LmEN9g`,
	"sendBtnIcon": `Hc5QZq92ObK7XcHL5Ire`
};
export default ___CSS_LOADER_EXPORT___;
