// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media(max-width: 800px){.jASVeO_fb_ntdV_AW0jo{justify-content:center}}`, "",{"version":3,"sources":["webpack://./src/UI/Components/ProfilePackages/styles.module.scss"],"names":[],"mappings":"AACI,yBADJ,sBAEQ,sBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `jASVeO_fb_ntdV_AW0jo`
};
export default ___CSS_LOADER_EXPORT___;
