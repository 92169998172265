import {FunctionComponent} from 'react';

import {LoginOutlined} from '@ant-design/icons';
import {Typography} from 'antd';
import {observer} from 'mobx-react';

import s from './styles.module.scss';

export const FormHeader: FunctionComponent = () => {
    return (
        <>
            <Typography.Title className={s.icon}>
                <LoginOutlined />
            </Typography.Title>
            <Typography.Title className={s.title}>AI Rabbit</Typography.Title>
        </>
    );
};

export default observer(FormHeader);
