import {FunctionComponent} from 'react';

import {Tabs} from 'antd';
import {observer} from 'mobx-react';

import ProfileBalances from '../ProfileBalances';
import ProfilePackages from '../ProfilePackages';
import ProfileSettings from '../ProfileSettings';
import s from './styles.module.scss';

const ProfileContent: FunctionComponent = () => {
    const items = [
        {
            label: 'Настройки',
            key: 'settings',
            children: <ProfileSettings />,
        },
        {
            label: 'Пакеты',
            key: 'packages',
            children: <ProfilePackages />,
        },
        {
            label: 'Остаток запросов',
            key: 'balances',
            children: <ProfileBalances />,
        },
    ];

    return <Tabs className={s.tabs} items={items} centered defaultActiveKey="settings" />;
};

export default observer(ProfileContent);
