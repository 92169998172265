import {action, makeObservable, observable, set} from 'mobx';

import {IMessageWord, IPrompt, IPromptField} from './types';

export default class PromptStore {
    constructor() {
        makeObservable(this);
    }

    @observable
    isLoading = false;

    @observable
    isCreatePromptModalVisible = false;

    @observable
    message = '';

    @observable
    messageWords: any[] = [];

    @observable
    prompt: IPrompt | null = null;

    @observable
    sendingPromptId: number | null = null;

    @observable
    savingPromptTitle = '';

    @observable
    savingPromptDescription = '';

    @observable
    savingPromptTemplate = '';

    @action
    setMessage = (): void => {
        this.message = this.messageWords.map(({value}) => value).join('');
    };

    @action
    setPrompt = (prompt: IPrompt | null): void => {
        this.prompt = prompt;
    };

    @action
    setPromptField = (field: string, value: string): void => {
        set(this.prompt ? this.prompt : {}, field, value);
    };

    @action
    setPromptFields = (fields: IPromptField[]): void => {
        fields.forEach((field) => {
            set(this.prompt ? this.prompt : {}, field.name, field.value);
        });
    };

    @action
    clearPrompt = (): void => {
        this.prompt = null;
        this.messageWords = [];
    };

    @action
    setSendingPromptId = (value: number): void => {
        this.sendingPromptId = value;
    };

    @action
    setIsCreatePromptModalVisible = (value: boolean): void => {
        this.isCreatePromptModalVisible = value;
    };

    @action
    updateMessageWord = (id: number, text: string): void => {
        this.messageWords[id].value = text;
        this.setMessage();
    };

    @action
    setMessageWords = (): void => {
        this.messageWords = this.mapPromptTemplate();
        this.setMessage();
    };

    @action
    setSavingPromptTitle = (value: string): void => {
        this.savingPromptTitle = value;
    };

    @action
    setSavingPromptDescription = (value: string): void => {
        this.savingPromptDescription = value;
    };

    @action
    setSavingPromptTemplate = (value: string): void => {
        this.savingPromptTemplate = value;
    };

    @action
    resetSavingPromptData = (): void => {
        this.savingPromptTitle = '';
        this.savingPromptDescription = '';
        this.savingPromptTemplate = '';
    };

    @action
    setIsLoading = (value: boolean): void => {
        this.isLoading = value;
    };

    private readonly mapPromptTemplate = (): IMessageWord[] => {
        let messageWords: IMessageWord[] = [];

        if (this.prompt == null) return messageWords;

        const reInput = /(\[[^]*?\])/g;
        const reBrackets = /\[|\]/g;

        const splits = this.prompt.template.split(reInput).filter((i) => i);

        messageWords = splits.map((split: string, index) => {
            const messageWord = {id: index, type: '', value: ''};

            if (reInput.test(split)) {
                messageWord.type = 'input';
                messageWord.value = split.replace(reBrackets, '');
            } else {
                messageWord.type = 'text';
                messageWord.value = split;
            }

            return messageWord;
        });

        return messageWords;
    };
}
