import {action, makeObservable, observable} from 'mobx';

import {IPrompt} from '../Prompt/types';

export default class PromptsListStore {
    constructor() {
        makeObservable(this);
    }

    @observable
    prompts: IPrompt[] = [];

    @action
    setPrompts = (prompts: IPrompt[]): void => {
        this.prompts = prompts;
    };
}
