// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gI2oUIga3hEAncCkhPHw{width:100%}.G_neEkYD_DIstaS8xshO{rotate:-90deg;margin-bottom:6px}.BhGJiEJ66HcpyCrbPF1r{width:50%}@media screen and (max-width: 800px){.BhGJiEJ66HcpyCrbPF1r{width:100%}}.hP5dMQKq0rKugrlNdiI3{padding-bottom:20px}.JbXspcTyUG3d3zCCsH79{width:100%;padding-bottom:10px}.JbXspcTyUG3d3zCCsH79 .so9dGZGfvhsP4ydXrtMA,.JbXspcTyUG3d3zCCsH79 .CVGeI06zDAYe_VQNdgAF,.JbXspcTyUG3d3zCCsH79 .k1uf3eGogRlbQODqj3Eg{width:100%}.JbXspcTyUG3d3zCCsH79 .IWRfqDmvWGebjREgRc1Z{margin-right:6px}`, "",{"version":3,"sources":["webpack://./src/UI/Components/ProfileBalances/styles.module.scss"],"names":[],"mappings":"AAKA,sBACI,UANS,CASb,sBACI,aAAA,CACA,iBARW,CAWf,sBACI,SAAA,CAEA,qCAHJ,sBAIQ,UAlBK,CAAA,CAsBb,sBACI,mBArBa,CAwBjB,sBACI,UA3BS,CA4BT,mBA3BY,CA6BZ,oIAGI,UAjCK,CAoCT,4CACI,gBAlCO","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `gI2oUIga3hEAncCkhPHw`,
	"progressLine": `G_neEkYD_DIstaS8xshO`,
	"balanceCard": `BhGJiEJ66HcpyCrbPF1r`,
	"header": `hP5dMQKq0rKugrlNdiI3`,
	"modelsRequests": `JbXspcTyUG3d3zCCsH79`,
	"modelTitle": `so9dGZGfvhsP4ydXrtMA`,
	"modelType": `CVGeI06zDAYe_VQNdgAF`,
	"quantity": `k1uf3eGogRlbQODqj3Eg`,
	"number": `IWRfqDmvWGebjREgRc1Z`
};
export default ___CSS_LOADER_EXPORT___;
