// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ctIBXJtA5NwbH_A8EK9a.ant-input-affix-wrapper{background-color:#121826 !important}.ctIBXJtA5NwbH_A8EK9a.ant-input-affix-wrapper.ant-input-affix-wrapper-focused{border-color:#d9d9d9}.ctIBXJtA5NwbH_A8EK9a.ant-input-affix-wrapper:focus{border-color:#d9d9d9}.ctIBXJtA5NwbH_A8EK9a.ant-input-affix-wrapper:hover{border-color:#d9d9d9}.ctIBXJtA5NwbH_A8EK9a.ant-input-affix-wrapper .anticon-search{color:#fff}.ctIBXJtA5NwbH_A8EK9a.ant-input-affix-wrapper>input{background:#121826 !important;color:#fff}.ctIBXJtA5NwbH_A8EK9a.ant-input-group-addon>button{background:#121826 !important;border-left:none}.ant-drawer-content{background:#121826 !important}.tukHhdYjE8DcbxiGkfg9 .RyBGriixhIaV6FfxmBdP{width:100%}.tukHhdYjE8DcbxiGkfg9 .ant-drawer-body{padding-top:0}.tukHhdYjE8DcbxiGkfg9 .ant-drawer-header{border:none;padding-inline:0;padding-bottom:0}.tukHhdYjE8DcbxiGkfg9 .ant-drawer-header .ant-drawer-close{color:#fff}.tukHhdYjE8DcbxiGkfg9 .ant-drawer-header-title{justify-content:end}@media(max-width: 800px){.tukHhdYjE8DcbxiGkfg9{width:100%}}.GbOOiEPIZZ3r3vcFcRcQ{width:max-content;background:linear-gradient(90deg, rgb(237, 173, 97) 0%, rgb(108, 97, 237) 50%, rgb(178, 97, 237) 100%);-webkit-background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.gTfdZ5JCyCz0CBxnB7id{width:100%;margin-bottom:10px}.gZgbCDnhnwunisJEXEhI{height:65%}`, "",{"version":3,"sources":["webpack://./src/UI/Components/PageWrapper/Menu/styles.module.scss"],"names":[],"mappings":"AACI,8CACI,mCAAA,CAEA,8EACI,oBAAA,CAGJ,oDACI,oBAAA,CAGJ,oDACI,oBAAA,CAGJ,8DACI,UAAA,CAGJ,oDACI,6BAAA,CACA,UAAA,CAIR,mDACI,6BAAA,CACA,gBAAA,CAIR,oBACI,6BAAA,CAIA,4CACI,UAAA,CAGJ,uCACI,aAAA,CAGJ,yCACI,WAAA,CACA,gBAAA,CACA,gBAAA,CAEA,2DACI,UAAA,CAIR,+CACI,mBAAA,CAGJ,yBAvBJ,sBAwBQ,UAAA,CAAA,CAIR,sBACI,iBAAA,CACA,sGAAA,CACA,4BAAA,CACA,qCAAA,CAGJ,sBACI,UAAA,CACA,kBAAA,CAGJ,sBACI,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navSearchInput": `ctIBXJtA5NwbH_A8EK9a`,
	"drawerMenu": `tukHhdYjE8DcbxiGkfg9`,
	"customHeader": `RyBGriixhIaV6FfxmBdP`,
	"headerTitle": `GbOOiEPIZZ3r3vcFcRcQ`,
	"newChatBtn": `gTfdZ5JCyCz0CBxnB7id`,
	"chatsRow": `gZgbCDnhnwunisJEXEhI`
};
export default ___CSS_LOADER_EXPORT___;
