import {FunctionComponent, useEffect} from 'react';

import {observer} from 'mobx-react';

import {useAuth} from '../../../Services/Auth';
import PageWrapper from '../PageWrapper';
import ProfileContent from '../ProfileContent';

const Profile: FunctionComponent = () => {
    const {getUser} = useAuth();

    useEffect(() => {
        void getUser();
    }, []);

    return (
        <PageWrapper>
            <ProfileContent />
        </PageWrapper>
    );
};

export default observer(Profile);
