// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YUussinZnuNx3A1m9S4K{width:100% !important;color:#fff;border-radius:10px;border:none;background:linear-gradient(90deg, rgb(237, 173, 97) 0%, rgb(108, 97, 237) 50%, rgb(178, 97, 237) 100%)}.FjcavQTX9dR9hoiS4lMq{width:100% !important;color:#fff;border-radius:10px;border:none;background:linear-gradient(90deg, rgb(237, 173, 97) 0%, rgb(108, 97, 237) 50%, rgb(178, 97, 237) 100%);height:40px !important}.F79eQzRuur4OOW5S7yBw{width:100% !important;color:#fff;border-radius:10px;border:none;background:linear-gradient(90deg, rgb(237, 173, 97) 0%, rgb(108, 97, 237) 50%, rgb(178, 97, 237) 100%);height:50px !important}`, "",{"version":3,"sources":["webpack://./src/UI/Components/PurchaseButton/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,qBAAA,CACA,UAAA,CACA,kBAAA,CACA,WAAA,CACA,sGAAA,CAEJ,sBACI,qBAAA,CACA,UAAA,CACA,kBAAA,CACA,WAAA,CACA,sGAAA,CACA,sBAAA,CAEJ,sBACI,qBAAA,CACA,UAAA,CACA,kBAAA,CACA,WAAA,CACA,sGAAA,CACA,sBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `YUussinZnuNx3A1m9S4K`,
	"smallButton": `FjcavQTX9dR9hoiS4lMq`,
	"largeButton": `F79eQzRuur4OOW5S7yBw`
};
export default ___CSS_LOADER_EXPORT___;
