// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YXzMesmslVRqwkXgkupU{color:#6c61ed;cursor:pointer}.T7hCQv4iZftxWf1EQKGR{max-width:10rem;height:25px;background:#fff;border-radius:5px;padding:0 5px}.sfH0OQhvg7TwidqgGUgg{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}`, "",{"version":3,"sources":["webpack://./src/UI/Components/PromptReset/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,aAAA,CACA,cAAA,CAGJ,sBACI,eAAA,CACA,WAAA,CACA,eAAA,CACA,iBAAA,CACA,aAAA,CAGJ,sBACI,kBAAA,CACA,eAAA,CACA,sBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeCloseIcon": `YXzMesmslVRqwkXgkupU`,
	"promptTitle": `T7hCQv4iZftxWf1EQKGR`,
	"text": `sfH0OQhvg7TwidqgGUgg`
};
export default ___CSS_LOADER_EXPORT___;
