import {FunctionComponent, useEffect} from 'react';

import {Input, Row} from 'antd';
import {observer} from 'mobx-react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';

import s from './styles.module.scss';
import ROUTES from '../../../Core/Const/Routes';
import {ERole} from '../../../Core/Enums';
import {usePromptStore, useSearchStore} from '../../../Services/Adapters/store';
import {usePrompts} from '../../../Services/Prompts';

interface IProps {
    sendMessage: () => Promise<void>;
}

const PromptTemplate: FunctionComponent<IProps> = ({sendMessage}: IProps) => {
    const navigate = useNavigate();
    const {isLoading, setCurrentMessage} = useSearchStore();
    const {message, setMessage, messageWords, updateMessageWord, setMessageWords} = usePromptStore();
    const {getPrompt} = usePrompts();
    const {promptId} = useParams();

    const handleOnChange = (id: number, text: string): void => {
        updateMessageWord(id, text);
    };

    const handlePressEnter = async (): Promise<void> => {
        if (!isLoading) {
            setMessageWords();
            setMessage();
            navigate(ROUTES.APP.PATH);
            setCurrentMessage(ERole.USER, message);
            await sendMessage();
        }
    };

    useEffect(() => {
        if (promptId) void getPrompt(parseInt(promptId));
    }, [promptId]);

    return (
        <Row align="middle" className={s.wrapper}>
            {messageWords.map((word) => {
                return (
                    <Row key={word.id} className={s.messageRow}>
                        {word.type === 'input' ? (
                            <Input
                                className={s.input}
                                placeholder={word?.value}
                                variant="borderless"
                                onInput={(e: any) => handleOnChange(word.id, e.target.value)}
                                onPressEnter={handlePressEnter}
                            />
                        ) : (
                            word.value
                        )}
                    </Row>
                );
            })}
        </Row>
    );
};
export default observer(PromptTemplate);
